<!--
 * @Author: Leo
 * @Description: 方案审核
-->
<template>
    <div v-if="!isLoading" class="edit-entrance">
        <router-view />
    </div>
</template>

<script>
import { serLogin } from "../../api/index"
export default {
    name: "EditEntrance",
    data() {
        return {
            isLoading: false,
        }
    },
    created() {
        this.$route.query.access_token && this.getToken()
    },
    methods: {
        // 获取登录态
        async getToken() {
            localStorage.clear()
            try {
                this.isLoading = true
                const res = await serLogin({ token: this.$route.query.access_token })
                if (res.data.token == null) {
                    this.$message.error("获取登录认证失败！")
                    return
                }
                if (res.code === 200) {
                    localStorage.setItem("token", res.data.token);
                    this.$store.commit("setToken")
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<style lang="less" scoped>
.complete-container {
    width: 960px;
    // margin: 20px 0;
    color: #606266;
    font-size: 14px;
}
.edit-entrance {
    height: 100%;
}
</style>
